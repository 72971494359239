
import AddAdministrator from '@/components/administrators/addadministrator/AddAdministrator.vue'
import AdministratorsList from '@/components/administrators/AdministratorsList.vue'
import { defineComponent } from 'vue'
export default defineComponent({
  components: { AddAdministrator, AdministratorsList },
  setup() {
    return {}
  }
})
