import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white border-b border-gray-200" }
const _hoisted_2 = { class: "px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800" }
const _hoisted_3 = { class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_4 = { class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_5 = { class: "px-6 py-4 whitespace-nowrap text-sm text-gray-500" }
const _hoisted_6 = { class: "px-6 py-4 whitespace-nowrap text-right text-sm font-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Switch = _resolveComponent("Switch")!
  const _component_BaseTableEditDeleteButtons = _resolveComponent("BaseTableEditDeleteButtons")!
  const _component_AreYouSureModal = _resolveComponent("AreYouSureModal")!

  return (_openBlock(), _createElementBlock("tr", _hoisted_1, [
    _createElementVNode("td", _hoisted_2, _toDisplayString(_ctx.admin.name), 1),
    _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.admin.email), 1),
    _createElementVNode("td", _hoisted_4, _toDisplayString(_ctx.admin.phone), 1),
    _createElementVNode("td", _hoisted_5, [
      _createVNode(_component_Switch, {
        value: _ctx.isSuperAdmin,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isSuperAdmin) = $event))
      }, null, 8, ["value"])
    ]),
    _createElementVNode("td", _hoisted_6, [
      _createVNode(_component_BaseTableEditDeleteButtons, {
        onOnDelete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.deleteAdminModal = true)),
        "show-delete": _ctx.amISuperAdmin,
        "show-edit": false
      }, null, 8, ["show-delete"]),
      (_ctx.deleteAdminModal)
        ? (_openBlock(), _createBlock(_component_AreYouSureModal, {
            key: 0,
            onOnAction: _ctx.onDeleteModalClose
          }, null, 8, ["onOnAction"]))
        : _createCommentVNode("", true)
    ])
  ]))
}