
import { defineComponent, ref } from 'vue'
import ModalWrapper from '@/components/utils/modal/ModalWrapper.vue'
import { useAdminsStore } from '@/store/admins/AdminsStore'
import NewUserForm from '@/components/forms/NewUserForm.vue'
import { AddUserInput } from '@/shared/types'

export default defineComponent({
  emits: ['onClose'],
  components: { ModalWrapper, NewUserForm },
  setup(_, { emit }) {
    const adminsStore = useAdminsStore()
    const isOpen = ref(true)
    const addAdmin = async (admin: AddUserInput) => {
      await adminsStore.addAdmin(admin)
      emit('onClose')
    }

    return {
      isOpen,
      addAdmin
    }
  }
})
