
import ButtonAdd from "@/components/utils/buttons/ButtonAdd.vue";
import { defineComponent, ref } from "vue";
import AddAdministratorModal from "./AddAdministratorModal.vue";

export default defineComponent({
  components: { AddAdministratorModal, ButtonAdd },
  setup() {
    const addAdministratorModal = ref(false);
    return { addAdministratorModal };
  },
});
