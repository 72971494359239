import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddAdministrator = _resolveComponent("AddAdministrator")!
  const _component_AdministratorsList = _resolveComponent("AdministratorsList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AddAdministrator, { class: "px-6 mt-4 w-full inline-flex justify-end" }),
    _createVNode(_component_AdministratorsList)
  ], 64))
}