
import { computed, defineComponent, onMounted } from 'vue'
import AdministratorsListItem from './AdministratorsListItem.vue'
import { useAdminsStore } from '@/store/admins/AdminsStore'
export default defineComponent({
  components: { AdministratorsListItem },
  setup() {
    const adminsStore = useAdminsStore()
    const adminsState = adminsStore.getState()
    onMounted(() => {
      adminsStore.setAdmins(false)
    })
    const admins = computed(() => adminsState.admins)

    return { admins }
  }
})
