
import {computed, defineComponent, PropType, ref} from 'vue'
import Switch from '@/components/utils/buttons/Switch.vue'
import {useAdminsStore} from '@/store/admins/AdminsStore'
import {GetAdminResult} from '@/shared/types'
import BaseTableEditDeleteButtons from "@/components/utils/tables/BaseTableEditDeleteButtons.vue";
import AreYouSureModal from "@/components/utils/modal/AreYouSureModal.vue";
import {useUserStore} from "@/store/user/UserStore";

export default defineComponent({
  components: {AreYouSureModal, BaseTableEditDeleteButtons, Switch},
  props: {
    admin: {
      type: Object as PropType<GetAdminResult>,
      required: true
    }
  },
  setup(props) {
    const adminsStore = useAdminsStore()
    const userStore = useUserStore()
    const userState = userStore.getState()

    const amISuperAdmin = computed(() => userState.superAdmin)

    const isSuperAdmin = computed({
      get(): boolean {
        return props.admin.superAdmin
      },
      set(newValue: boolean) {
        adminsStore.setSuperAdmin({email: props.admin.email, superAdmin: newValue})
      }
    })

    const deleteAdminModal = ref(false)

    const deleteAdmin = async () => {
      // props.admin.email ? await adminsStore.deleteAdmin(props.admin.email) : undefined
    }

    const onDeleteModalClose = async (action: boolean) => {
      if (action) await deleteAdmin()
      deleteAdminModal.value = false
    }
    return {isSuperAdmin, deleteAdminModal, deleteAdmin, onDeleteModalClose,amISuperAdmin}
  }
})
